<template>
   <div class="account">
      <div class="mobil_padding">
         <router-link class="header__pricing" :to="{ name: 'setting' }">
            <div class="img__pricing">
               <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M7.57391 12.4252C7.30054 12.6986 6.85732 12.6986 6.58396 12.4252L1.15341 6.99466C0.880043 6.72129 0.880043 6.27808 1.15341 6.00471L6.58396 0.574165C6.85732 0.300798 7.30054 0.300798 7.57391 0.574165L8.17495 1.17521C8.44831 1.44857 8.44831 1.89179 8.17495 2.16516L4.96484 5.37526L16.2482 5.37526C16.594 5.37526 16.875 5.67788 16.875 6.05026V6.95026C16.875 7.32263 16.594 7.62526 16.2482 7.62526L4.96599 7.62526L8.17495 10.8342C8.44831 11.1076 8.44831 11.5508 8.17495 11.8242L7.57391 12.4252Z"
                  />
               </svg>
            </div>
            <div class="back__pricing">
               <Typography :weigth="'weigth'" :size="'bigger'">Account</Typography>
            </div>
         </router-link>
      </div>
      <div class="account-content">
         <div class="thmeme-mode-item">
            <Typography class="colorCurrent" :size="'small'"> {{ $t('Account.fontSize') }} </Typography>
            <div>
               <ul class="list-font-size">
                  <li
                     class="list-font-size-item"
                     :class="`${size} ${fontSize === size ? 'active' : ''}`"
                     v-for="size in sizes"
                     :key="size"
                  >
                     <button @click="changeFontSize(size)">A</button>
                  </li>
               </ul>
            </div>
         </div>
         <div class="thmeme-mode-item">
            <Typography class="colorCurrent" :size="'small'"> {{ $t('Account.nigthMode') }}</Typography>
            <SWSwitcher v-model="nigthMode" />
         </div>

         <div class="profile-items">
            <div class="profile-item photo">
               <div class="image">
                  <img :src="image" />
               </div>
               <div>
                  <SWButton :size="'small'" variant="secondary" @click="$refs.imageInput.click()">
                     <Typography class="colorBtnText"> {{ $t('Account.selectImage') }} </Typography>
                  </SWButton>
                  <input
                     ref="imageInput"
                     style="display: none"
                     type="file"
                     accept="image/*, .heic, .heif"
                     @change="readFileUrl"
                     @click="imgErrorMessage = false"
                  />
               </div>
               <span v-if="imgErrorMessage" class="imgError"> the image size is too large</span>
            </div>
            <form @submit.prevent autocomplete="off">
               <div class="profile-item">
                  <SWInput
                     v-model="profile.first_name"
                     :isError="$v.profile.first_name.$error && !!firstNameError"
                     @input="$v.profile.first_name.$touch()"
                     @blur.capture="clearErrorOnBlur('first_name')"
                     :placeholder="$t('Account.placeholderFirstName')"
                     :errorMessage="firstNameError"
                     name="first_name"
                     autocomplete="first_name"
                  />
               </div>
               <div class="profile-item">
                  <SWInput
                     v-model="profile.email"
                     :placeholder="$t('Account.placeholderEmail')"
                     type="email"
                     name="email"
                     :isError="$v.profile.email.$error && !!emailError"
                     :errorMessage="emailError"
                     @blur.capture="clearErrorOnBlur('email')"
                     @input="$v.profile.email.$touch()"
                     autocomplete="email"
                  />
               </div>
               <div class="profile-item">
                  <SWInput
                     v-model="profile.phone"
                     :isError="$v.profile.phone.$error && !!phoneError"
                     @blur.capture="clearErrorOnBlur('phone')"
                     @input="$v.profile.phone.$touch()"
                     :errorMessage="phoneError"
                     :placeholder="$t('Account.placeholderPhone')"
                     type="tel"
                     name="tel"
                     autocomplete="tel"
                     v-phone-number
                  />
               </div>

               <SWButton variant="secondary" type="button" fullWidth @click="ischangePassOpen" :disabled="formMutex">
                  <Typography class="colorBtnText">
                     {{ $t('Account.changePass') }}
                  </Typography>
               </SWButton>
               <transition name="fade">
                  <div v-if="showChangePasswordInput" class="changePassword">
                     <div class="profile-item placeholder">
                        <SWInput
                           :errorMessage="currentPasswordError"
                           :isError="$v.currentPassword.$error && !!currentPasswordError"
                           @blur.capture="$v.currentPassword.$reset()"
                           @input="$v.currentPassword.$touch()"
                           v-model="currentPassword"
                           :placeholder="showOnlyNewPasswordRequired ? 'Newol password' : 'Current Password'"
                           ref="currentpassword"
                           type="password"
                        />
                        <!-- <span
                        @click="goToInput"
                        v-if="newPassword.length === 0 && !($v.newPassword.$error && !!passwordError)"
                        class="text"
                        >{{ $t('Account.placeholderPasswordhint') }}</span
                     > -->
                     </div>
                     <div v-if="!showOnlyNewPasswordRequired" class="profile-item placeholder">
                        <SWInput
                           :errorMessage="passwordError"
                           :isError="($v.currentPassword.$error && !!currentPasswordError) || passwordRequired"
                           @blur.capture="$v.newPassword.$reset()"
                           @input="$v.newPassword.$touch()"
                           v-model="newPassword"
                           :placeholder="$t('Account.placeholderPassword')"
                           ref="password"
                           type="password"
                        />
                        <!-- <span
                        @click="goToInput"
                        v-if="newPassword.length === 0 && !($v.newPassword.$error && !!passwordError)"
                        class="text"
                        >{{ $t('Account.placeholderPasswordhint') }}</span
                     > -->
                     </div>
                  </div>
               </transition>
            </form>
         </div>

         <div class="connect">
            <Typography class="colorBtnText" :weigth="'500'">{{ $t('Account.connect') }}</Typography>
            <div class="connect-items">
               <SWButton :size="'small'" variant="secondary" @click="bindGoogle" :disabled="!!profile.google_id">
                  <Typography class="colorBtnText">{{ $t('Account.google') }}</Typography>
               </SWButton>
               <SWButton :size="'small'" variant="secondary" @click="bindTwitter" :disabled="!!profile.twitter_id">
                  <Typography class="colorBtnText">{{ $t('Account.twitter') }}</Typography>
               </SWButton>
               <SWButton :size="'small'" variant="secondary" @click="bindFacebook" :disabled="!!profile.facebook_id">
                  <Typography class="colorBtnText">{{ $t('Account.facebook') }}</Typography>
               </SWButton>
            </div>
         </div>

         <div class="save">
            <SWButton :disabled="!isFormCorrert" type="submit" fullWidth @click="onSave">
               <Typography class="colorButton">
                  {{ $t('Account.save') }}
               </Typography>
            </SWButton>
         </div>
      </div>
      <Notification
         :show="notificationShowed"
         :text="notificationText"
         :color="notificationColor"
         @closeNotification="closeNotification"
      />
   </div>
</template>

<script>
import { SWSwitcher, SWButton, SWInput } from 'sw-ui-kit';
import Typography from '../../../components/UI/Typography.vue';
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { hasPassword, hasNumberPhone, hasEmail } from '../../../validation/validation';
import { DEFAULT_SIZE, KEY_FONT_SIZE, KEY_THEME, SIZES } from '../../../store/themeModes';
import remoteOrigin from '../../../store/remoteOrigin';
//import log from '../../../logger/logger';
import Notification from '../../../components/UI/Notification.vue';

export default {
   MAX_FILE_SIZE: 1000000,
   components: { Typography, SWSwitcher, SWButton, SWInput, Notification },
   mixins: [validationMixin],
   inject: ['changeThemeMode', 'changeFontSizeStyle'],

   data() {
      return {
         fontSize: DEFAULT_SIZE,
         nigthMode: false,
         imgErrorMessage: false,
         newPassword: '',
         currentPassword: '',
         profile: { email: '', phone: '', first_name: '' },
         file: '',
         currentProfile: { email: '', phone: '', first_name: '' },
         showPlaceholder: true,
         showChangePasswordInput: false,
         showOnlyNewPasswordRequired: false,
         passwordRequired: false,
         notificationText: '',
         notificationColor: '',
         notificationShowed: false,
      };
   },
   validations: {
      newPassword: {
         required,
         hasPassword,
         maxLength: maxLength(20),
         minLength: minLength(8),
      },
      currentPassword: {
         required,
         hasPassword,
         maxLength: maxLength(20),
         minLength: minLength(8),
      },
      profile: {
         email: {
            required,
            hasEmail,
         },
         phone: {
            required,
            hasNumberPhone,
         },
         first_name: {
            required,
            maxLength: maxLength(32),
            minLength: minLength(1),
         },
      },
   },

   created() {
      this.requestUser();
   },

   mounted() {
      // this.$refs.currentpassword.$el.querySelector('input').onblur = this.removeErrorCurrentPassword;
      // this.$refs.password.$el.querySelector('input').onblur = this.removeErrorNewPassword;
      const fontSize = localStorage.getItem(KEY_FONT_SIZE);
      if (fontSize && SIZES.includes(fontSize)) this.fontSize = fontSize;

      let nigthMode;
      try {
         nigthMode = JSON.parse(localStorage.getItem(KEY_THEME));
      } catch {
         nigthMode = false;
      }

      this.nigthMode = nigthMode;
     // log(this.$http, { message: 'visited page /settings/account', level: 'info' });
   },

   beforeDestroy() {
      //this.$refs.password.$el.querySelector('input').onblur = null;
      //this.$refs.currentpassword.$el.querySelector('input').onblur = null;
   },

   methods: {
      showNotification(text, color) {
         this.notificationShowed = true;
         this.notificationText = text;
         this.notificationColor = color;
      },
      closeNotification() {
         this.notificationShowed = false;
      },
      ischangePassOpen() {
         this.showChangePasswordInput = !this.showChangePasswordInput;
         this.showOnlyNewPasswordRequired = false;
      },
      goToInput() {
         this.$refs.password.$el.querySelector('input').focus();
      },
      removeErrorNewPassword() {
         if (this.newPassword.length === 0) this.$v.newPassword.$reset();
      },
      removeErrorCurrentPassword() {
         if (this.currentPassword.length === 0) this.$v.currentPassword.$reset();
      },
      clearErrorOnBlur(key) {
         if (this.profile[key].length === 0) this.$v.profile[key].$reset();
      },
      changeFontSize(size) {
         if (!size && !SIZES.includes(size)) return;
         this.fontSize = size;
      },
      sendnigthMode() {
         this.$root.$emit('nigthMode', this.nigthMode);
      },

      setImage(file) {
         this.profile.img = file;
      },

      async requestUser() {
         this.$http.post('resources/profile').then(data => {
            this.profile = { ...data.data };
            this.currentProfile = { ...data.data };
         });
      },

      readAndSetImage(file) {
         const self = this;
         const reader = new FileReader();
         reader.onload = function (e) {
            self.setImage(e.target.result);
         };
         reader.readAsDataURL(file);
      },

      async readFileUrl(e) {
         const file = e.target.files[0];
         //TODO: handle error max size
         if (file.size >= this.$options.MAX_FILE_SIZE) {
            this.imgErrorMessage = true;
            return this.imgErrorMessage;
         }
         this.readAndSetImage(file);
         const formData = new FormData();
         formData.append('img', file);
         this.file = formData;
      },

      saveImage() {
         if (!this.file) return;
         this.$http
            .put('resources/profile', this.file, {
               headers: {
                  'Content-Type': 'multipart/form-data',
               },
            })
            .then(() => {});
      },

      updateFirstName(name) {
         this.$http.put('resources/profile', { first_name: name }).then(() => {});
      },

      async updatePhone(phone) {
         const data = { phone: phone, ...(this.currentPassword ? { password: this.currentPassword } : {}) };
         console.log('🚀 ~ file: Account.vue:366 ~ updatePhone ~ data:', data);

         try {
            const response = await this.$http.put('resources/profile/asign/phone', data);
            console.log(response);
            if (response) {
               this.requestUser();
               this.showNotification('Your phone has been updated', 'info');
            }
         } catch (e) {
            if (e.status === 400) {
               this.passwordRequired = true;
               this.showChangePasswordInput = true;
               this.showOnlyNewPasswordRequired = true;
               this.showNotification('New passwrod is required ', 'warning');
            }
            if (e.status === 403 && e.data.message === 'An account with this phone number already exists') {
               this.showNotification('An account with this phone number already exists ', 'danger');
            }
         }

         // console.log('updating phone');
         //this.$http.put('resources/profile', { phone: phone }).then(() => {});
      },

      async updateEmail(email) {
         const data = { email: email, ...(this.currentPassword ? { password: this.currentPassword } : {}) };
         try {
            const response = await this.$http.put('resources/profile/asign/email', data);
            if (response) {
               this.requestUser();
               this.showNotification('Your email has been updated', 'info');
            }
         } catch (e) {
            if (e.status === 400) {
               this.passwordRequired = true;
               this.showChangePasswordInput = true;
               this.showOnlyNewPasswordRequired = true;
               this.showNotification('New passwrod is required ', 'warning');
            }
            if (e.status === 403 && e.data.message === 'Email already exists') {
               this.showNotification('An account with this email already exists ', 'danger');
            }
         }

         //this.$http.put('resources/profile', { email: email }).then(() => {});
      },
      async changePassword() {
         try {
            await this.$http.put('resources/profile/password', {
               password: this.currentPassword,
               new_password: this.newPassword,
            });

            this.showNotification('Password has been changed', 'info');
         } catch (error) {
            this.showNotification('Invalid password', 'danger');
            this.$refs['form'].reset();
         }
      },

      // updatePassword(password) {
      //    this.$http.put('resources/newPassword', { newPassword: password }).then(() => {});
      // },

      onSave() {
         if (this.profile.img && this.profile.img !== this.currentProfile.img) {
            this.saveImage();
         }

         if (this.profile.first_name && this.profile.first_name !== this.currentProfile.first_name) {
            this.updateFirstName(this.profile.first_name);
         }
         if (this.profile.phone && this.profile.phone !== this.currentProfile.phone) {
            this.profile.phone = this.profile.phone.charAt(0) === '+' ? this.profile.phone : '+' + this.profile.phone;
            this.updatePhone(this.profile.phone);
         }
         if (this.profile.email && this.profile.email !== this.currentProfile.email) {
            this.updateEmail(this.profile.email);
         }
         if (
            this.showChangePasswordInput &&
            !this.currentPasswordError &&
            !this.passwordError &&
            !this.passwordRequired
         ) {
            this.changePassword();
            this.showChangePasswordInput = false;
            this.newPassword = '';
            this.currentPassword = '';
         }
         // if (this.newPassword) {
         //    this.updatePassword(this.newPassword);
         // }
      },

      disabled() {
         if (this.isFormCorrert) {
            return;
         }
      },

      async bindGoogle() {
         if (this.profile?.google_id) return;
         let url = await this.fetchGooleUrl();
         localStorage.isAssign = true;
         // if (this.$route.name === 'app-profile') {
         // 	localStorage.isAssign = true;
         // }
         window.location.href = url;
      },

      async fetchGooleUrl() {
         let response = await this.$http.post('auth/google/login/url');
         return response.data.url;
      },

      async bindTwitter() {
         if (this.profile?.twitter_id) return;
         let url = await this.fetchTwitterUrl();
         localStorage.isAssign = true;
         // if (this.$route.name === 'app-profile') {
         //    localStorage.isAssign = true;
         // }
         window.location.href = url;
      },

      async fetchTwitterUrl() {
         let response = await this.$http.post('auth/twitter/login/url', null, { withCredentials: true });
         return response.data.redirectUrl;
      },

      async bindFacebook() {
         if (this.profile?.facebook_id) return;
         let url = await this.fetchFaceBookUrl();
         localStorage.isAssign = true;
         // if (this.$route.name === 'app-profile') {
         //    localStorage.isAssign = true;
         // }
       //  this.$http.post('/resources/log', { message: 'Try to bind account with facebook', level: 'info' });
         window.location.href = url;
      },

      async fetchFaceBookUrl() {
         let response = await this.$http.post('auth/facebook/login/url');
         return response.data.url;
      },
   },

   computed: {
      formMutex() {
         return (
            (this.profile.email && this.profile.email !== this.currentProfile.email) ||
            (this.profile.phone && this.profile.phone !== this.currentProfile.phone) ||
            (this.profile.img && this.profile.img !== this.currentProfile.img) ||
            (this.profile.first_name && this.profile.first_name !== this.currentProfile.first_name)
         );
      },

      passwordError() {
         if (this.newPassword && !this.$v.newPassword.required) return 'Enter your current or new password';
         if (!this.$v.newPassword.minLength) return 'Password must be at least 8 characters long';
         if (!this.$v.newPassword.maxLength) return 'Password must not exceed 20 characters';
         if (!this.$v.newPassword.hasPassword)
            return 'The password must have Latin capital and small letters,numbers and a special symbol ( ! @ # $ & + - ?)';
         return '';
      },
      currentPasswordError() {
         if (this.currentPassword && !this.$v.currentPassword.required) return 'Enter your current or new password';
         if (!this.$v.currentPassword.minLength) return 'Password must be at least 8 characters long';
         if (!this.$v.currentPassword.maxLength) return 'Password must not exceed 20 characters';
         if (!this.$v.currentPassword.hasPassword)
            return 'The password must have Latin capital and small letters,numbers and a special symbol ( ! @ # $ & + - ?)';
         return '';
      },

      emailError() {
         if (this.currentProfile.email && !this.$v.profile.email.required) return 'Enter your current or new email';
         if (!this.$v.profile.email.hasEmail)
            return 'The email can have Latin capital and small letters,numbers and a special symbol (! # $ % & ‘ * + — / = ? ^ _ ` { | } ~;)';
         return '';
      },
      firstNameError() {
         if (this.currentProfile.first_name && !this.$v.profile.first_name.required)
            return 'Enter your current or new name';
         if (!this.$v.profile.first_name.minLength) return 'The firstName must consist of a minimum of characters';
         if (!this.$v.profile.first_name.maxLength) return 'The firstName should not exceed 32 characters';
         if (!this.$v.profile.first_name.hasUpperlowerCase)
            return 'The name can have Latin capital and small letters, numbers and a special symbol (@ # $ ! & ? ^ _)';
         return '';
      },
      phoneError() {
         if (this.currentProfile.phone && !this.$v.profile.phone.required) return 'Enter your current or new phone';
         if (!this.$v.profile.phone.maxLength) return 'Phone must not exceed 20 numbers';
         if (!this.$v.profile.phone.minLength) return 'Phone must be exceed 10 numbers';
         if (!this.$v.profile.phone.hasNumberPhone)
            return 'Phone can have numbers special symbol "+ () -" and length max22 ';
         return '';
      },
      isFormCorrert() {
         return !this.$v.profile.email.$error && !this.$v.profile.phone.$error && !this.$v.profile.first_name.$error;
      },
      sizes() {
         return SIZES;
      },
      userEmptyImage() {
         return require('../../../assets/img/userEmty.png');
      },

      origin() {
         return remoteOrigin;
      },

      image() {
         if (!this.profile.img) return localStorage.social_img || this.userEmptyImage;
         else if (this.profile.img === this.currentProfile.img) return this.origin + this.profile.img;
         else return this.profile.img;
      },
   },

   watch: {
      fontSize() {
         localStorage.setItem(KEY_FONT_SIZE, this.fontSize);
         this.changeFontSizeStyle(this.fontSize);
      },
      nigthMode() {
         localStorage.setItem(KEY_THEME, JSON.stringify(this.nigthMode));
         this.changeThemeMode(this.nigthMode);
      },
   },
};
</script>

<style scoped>
.account {
   width: 100%;
   overflow-y: auto;
   height: 76svh;
}
/* .buttonCharacter {
   color: var(--color-character-acount);
} */

.changePassword {
   margin-top: 24px;
}

.account-content {
   margin: 0 auto;
   max-width: 340px;
   width: 100%;
}

.thmeme-mode-item {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 24px;
   padding: 3.5px 0 3.5px 24px;
}

.thmeme-mode-item:last-child {
   margin-bottom: 40px;
}

.list-font-size {
   margin-left: 16px;
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   column-gap: 24px;
   align-items: center;
}

.list-font-size-item.small button {
   font-size: 16px;
}

.list-font-size-item.default button {
   font-size: 24px;
}

.list-font-size-item.big button {
   font-size: 30px;
}

.list-font-size-item button {
   font-weight: 400;
   transition: all 0.3s;
}
.list-font-size-item button {
   color: var(--typograohy-color);
}

.list-font-size-item:hover button {
   color: var(--shape-hover);
}

.active button {
   color: inherit;
}

.profile-items {
   margin-top: 40px;
}

.profile-item {
   margin-bottom: 24px;
   position: relative;
}

.profile-item.photo {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 32px;
}

.profile-item .image {
   width: 81px;
   height: 81px;
}

.profile-item .image img {
   border-radius: 50%;
   height: 100%;
   object-fit: contain;
}

.placeholder {
   position: relative;
}

.placeholder .text {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-16%, -50%);
   width: 100%;
   color: var(--text-desabled);
   font-size: 11px;
}

.placeholder-new-pass-on-error.text {
   top: 27%;
}

.connect {
   margin-top: 40px;
   text-align: center;
}

.connect-items {
   margin-top: 16px;
   display: grid;
   column-gap: 17px;
   grid-template-columns: repeat(3, 1fr);
}

.save {
   margin-top: 32px;
}

.header__pricing {
   display: flex;
   font-size: 24px;
   cursor: pointer;
   font-weight: 700;
   line-height: 31.2px;
}
.img__pricing {
   width: 20px;
   height: 12.26px;
   margin-right: 33px;
}
.img__pricing svg {
   fill: var(--font-color-primary);
}
.imgInput {
   position: relative;
}
.imgError {
   position: absolute;
   top: 100%;
   bottom: 0;
   font-size: 11px;
   left: 5px;
   color: var(--text-alert);
}
.back__pricing {
   color: var(--typograohy-color);
}
.colorBtnText {
   color: var(--color-current-typography) !important;
}
.colorCurrent {
   color: var(--color-character-acount) !important;
}
.colorButton {
   color: var(--color-button-darck-team) !important;
}

@media screen and (max-width: 650px) {
   .header__pricing {
      display: flex;
      justify-content: center;
      align-items: center;
   }
   .img__pricing {
      height: unset;
   }
   .mobil_padding {
      position: fixed;
      top: 0px;
      left: 0;
      padding: 15px 20px 15px 0px;
      width: 100%;
      background-color: var(--bg-install-element);
      /* padding-bottom: 20px; */
   }
   /* .img__pricing {
      padding-top: 10px;
      padding-left: 19px;
   }*/
   .back__pricing p {
      font-size: 20px !important;
      /* padding-left: 108px;
      padding-top: 10px; */
   }
   .save {
      margin-bottom: 32px;
   }
}
@media screen and (max-width: 450px) {
   .account {
      height: unset;
   }
   .img__pricing {
      margin-right: 0;
      transform: translateX(-120px);
   }
   /* .back__pricing {
      transform: translateY(4px);
   } */
   /* .profile-items {
      margin-top: 20px;
   }
   .thmeme-mode-item {
      margin-bottom: 18px;
   }
   .profile-item {
      margin-bottom: 19px;
   }
   .connect {
      margin-top: 25px;
   }
   .layout_content {
      padding-bottom: 0px;
   } */
   .account-content {
      margin-top: 40px;
   }
}
@media screen and (max-width: 375px) {
   .thmeme-mode-item {
      margin-bottom: 7px;
   }
   .profile-item.photo {
      margin-bottom: 15px;
   }
   .connect {
      margin-top: 9px;
   }
   .save {
      margin-top: 13px;
   }
   .profile-items {
      margin-top: 12px;
   }
   .account-content {
      margin-top: 15px;
   }
   .profile-item {
      margin-bottom: 10px;
   }
}
</style>
